import React from "react"

import { Slider } from "components/anti/slider/slider"
import { Section, Container } from "components/anti/grid/grid"
import { Card } from "components/anti/card/card"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutValues = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const [slideTrigger, slideAnim] = useScrollAnim()
  const block = data

  return (
    <>
      <Section forwardRef={trigger} className="about-values">
        <Container>
          <div className="w-md-700px">
            {block.label && <h6 className={`h6 ${anim(1)}`}>{block.label}</h6>}
            {block.title && <h2 className={`h2 ${anim(2)} `}>{block.title}</h2>}
            {block.text && <p className={`mb-0 ${anim(3)} `}>{block.text}</p>}
          </div>
        </Container>
      </Section>

      <div ref={slideTrigger}>
        {block.values && (
          <Slider
            autoplay={true}
            autoplaySpeed={3000}
            infinite={true}
            visibleInitial={true}
            visibleLgDown={true}
            visibleMdDown={true}
            visibleSmDown={true}
            showInitial={4}
            showLgDown={3}
            showMdDown={2}
            showSmDown={1.5}
            arrowsInitial={true}
            arrowsLgDown={true}
            arrowsMdDown={false}
            arrowsSmDown={false}
            className={`slick-px-0 values-slider`}
          >
            {block.values.map((item, i) => {
              return (
                <Card
                  isLink={false}
                  variant="overlay"
                  img={item?.image?.sourceUrl}
                  imgRatio="r-2-3"
                  title={item?.title}
                  text={item?.text}
                  key={i}
                  className={`card-value active-hover`}
                />
              )
            })}
          </Slider>
        )}
      </div>
    </>
  )
}
