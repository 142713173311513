import React, { useContext } from "react"

import { Button } from "components/anti/buttons/buttons"
import { Section, Container } from "components/anti/grid/grid"
import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"

import { LangContext } from "../../../context/lang-context"
import { useScrollAnim } from "src/components/hooks/hooks"

export const AboutRecognitions = ({ data, text }) => {
  const { lang } = useContext(LangContext)
  const [trigger, anim] = useScrollAnim()
  const cardList = data

  return (
    <Section forwardRef={trigger} className="about-award">
      <Container>
        <div className="w-md-600px mb-5">
          {text?.label && <h6 className={`h6 ${anim(1)}`}>{text.label}</h6>}
          {text?.title && <h2 className={`h2 ${anim(2)}`}>{text.title}</h2>}
          {text?.text && <p className={`${anim(3)}`}>{text.text}</p>}
          {text?.button && (
            <div className={`btn-group d-block ${anim(4)}`}>
              <Button variant="link" link={text.button.url}>
                {text.button.text}
              </Button>
            </div>
          )}
        </div>
        <Slider
          visibleInitial={true}
          visibleLgDown={true}
          visibleMdDown={true}
          visibleSmDown={true}
          showInitial={3}
          showXlDown={3}
          showLgDown={3}
          showMdDown={2}
          showSmDown={1.5}
          arrowsInitial={false}
          arrowsLgDown={false}
          arrowsMdDown={false}
          arrowsSmDown={false}
        >
          {cardList.awards &&
            cardList.awards.map((item, i) => {
              return (
                <Card
                  isLink={false}
                  variant="boxless"
                  img={item?.award?.image?.sourceUrl}
                  imgRatio="r-3-2"
                  label={item?.award?.from}
                  title={item?.award?.name}
                  className={`card-award ${anim(5, "fadeInUp")}`}
                  key={i}
                >
                  <p className="card-text">
                    {lang === "EN" ? "Awarded to" : "Diberikan Kepada"}{" "}
                    <strong>{item?.award?.to}</strong>
                  </p>
                </Card>
              )
            })}
        </Slider>
      </Container>
    </Section>
  )
}
