import React from "react"

import { Button } from "components/anti/buttons/buttons"
import { Section, Container } from "components/anti/grid/grid"
import { useScrollAnim } from "src/components/hooks/hooks"

//asset
import Hi from "../../../assets/img/common/combined-shape.png"

export const AboutTeam = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data

  return (
    <Section forwardRef={trigger} className="about-team light">
      <Container>
        <div className={`cover dark`}>
          <div className={`cover-bg-wrapper`}>
            <div className={`cover-content mw-400px mw-md-600px pr-md-5`}>
              {block.label && (
                <h6 className={`h6 ${anim(1)}`}>{block.label}</h6>
              )}
              {block.title && (
                <h2 className={`h2 mb-3 ${anim(2)}`}>{block.title}</h2>
              )}
              {block.text && (
                <div
                  dangerouslySetInnerHTML={{ __html: block.text }}
                  className={`cover-text pr-md-5 mr-md-4 ${anim(3)}`}
                />
              )}
              {block.button && (
                <div className={`btn-group d-block ${anim(4)}`}>
                  <Button variant="link" link={block?.button?.url}>
                    {block?.button?.text}
                  </Button>
                </div>
              )}
              <div className="bubble-cover position-absolute">
                <img
                  src={Hi}
                  className={`graphic-wrapper d-md-block d-none position-absolute`}
                  alt="graphic"
                />
                <div className="rect-gfx position-absolute" />
              </div>
            </div>
            <div>
              <img
                src={block?.image?.sourceUrl}
                className={`position-absolute img-content ${anim(5)}`}
                alt="Team"
              />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}
